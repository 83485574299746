import React from "react";
import ReactDOM from "react-dom/client";
import {faBars} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {keyframes} from 'styled-components';
import styled from 'styled-components';
import portrait from "../img/portrait.jpg"
import AltProject from "./AltProject";
import zen from "../img/zen.webp"

const Content = styled.div`
    line-height: 1.5rem;
    max-width: 1000px;
    width: 90%;
  height: 100%;
  display:grid;
  grid-gap:2em;
  justify-items: center;
  display: grid;
  grid-column-gap:2rem;

  @media (min-width: 600px) {

  justify-items: center;
   grid-template-columns: repeat(2, 1fr); 
}

  @media (min-width: 900px) {
  justify-items: center;
  grid-template-columns: repeat(2, 1fr); 
}
}
`

const Title = styled.h1`
  text-align:center;
  color: #F90141;
  font-weight:bold;
  font-size: 2.5em;
  margin-bottom:1em;
`

const Container = styled.div`
  margin-top:3em;
  margin-bottom:5em;
  display: flex;
    flex-direction: column;
    align-items: center;

`
const AltProjectSection = () => {
    return (
      <Container>
      <Title>My Other Projects</Title>
      <Content>
        <AltProject
          title="Zen"
          desc="Zen is a Digital Agency website where users are provided aid with creative design and technical development."
          techCsv="HTML, CSS, Javascript"
          projectLink="https://zen.hameddiakite.com"
          img={zen}
        />
      </Content>
      </Container>
    );
}

export default AltProjectSection;
