import React from "react";
import ReactDOM from "react-dom/client";
import {faBars} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {keyframes} from 'styled-components';
import styled from 'styled-components';
import portrait from "../img/portrait.jpg"
import AltProject from "./AltProject";
import Project from "./Project";
import giornos from "../img/giornos.webp"
import jobvenue from "../img/jobvenue.webp"
import soon from "../img/soon.webp"
import robot from "../img/robot.webp"
import robot2 from "../img/robot2.webp"

const Content = styled.div`
`

const Title = styled.h1`
  text-align:center;
  color: #F90141;
  font-weight:bold;
  font-size: 2.5em;
  margin-bottom:1em;
`

const Container = styled.div`
  margin-top:3em;

`
const ProjectSection = () => {
    return (
      <Container >
        <Title id="projectSection" >My Projects</Title>
        <Content>
          <Project
            isSwap={true}
            desc="Coming Soon...  A 2D platformer!"
            img={soon}
          techCsv="PhaserJS,HTML,CSS"
          />
          <Project 
          isSwap={true}
          title="Human Pose Controlled Robot" 
          img={robot2}
          desc="Control a robotic car with specific body poses!"
          techCsv="Arduino,Python,P5.js,ML5.js"
          projectLink="https://www.youtube.com/watch?v=0zqI3GaFQnQ"
          />
          <Project
            img={jobvenue}
            desc="Jobvenue is a platform for job seekers and employers to create and apply for jobs."
            title="JobVenue"
          techCsv="PHP,MySQL,NGINX,Sass"
          projectLink="https://jobvenue.hameddiakite.com"
          />
          <Project 
            isSwap={true}
          desc="A virtual robot that copies your movement in real time using machine learning!" 
          img={robot}
          title="Gesture Controlled Robot"
          techCsv="Arduino,Python,P5.js,ML5.js"
          projectLink="https://www.youtube.com/watch?v=rRY6F7WQs_w"
          />
          <Project 
          desc="Hungry? Giornos has you covered. Giornos Golden Experience is a static restaraunt landing page. Come check it out" 
          img={giornos}
          title="Giornos"
          techCsv="Gsap,React,JS"
          projectLink="https://giornos.hameddiakite.com"
          />
        </Content>
      </Container>
    );
}

export default ProjectSection;
