import { Tilt as Hamburger } from './Tilt';
import { Cross } from './Cross';
import { Divide } from './Divide';
import { Fade } from './Fade';
import { Pivot } from './Pivot';
import { Rotate } from './Rotate';
import { Slant } from './Slant';
import { Sling } from './Sling';
import { Spin } from './Spin';
import { Spiral } from './Spiral';
import { Squash } from './Squash';
import { Squeeze } from './Squeeze';
import { Turn } from './Turn';
import { Twirl } from './Twirl';
export default Hamburger;
export { Cross, Divide, Fade, Pivot, Rotate, Slant, Sling, Spin, Spiral, Squash, Squeeze, Turn, Twirl };