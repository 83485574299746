import React, { useState, useEffect,useRef } from "react";
import { Squash as Hamburger } from 'hamburger-react'
import ReactDOM from "react-dom/client";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faLinkedinIn,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import styled from "styled-components";

import {motion} from "framer-motion"

import logo from "../img/pancakeswap.png";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const Logo = styled.img`
  width: 2.5em;
`;

const StyledNav = styled(motion.nav)`
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight:bold;
    height:3.5em;
    position:fixed;
    z-index:999999;
    width:100%;
    padding-top:0.5em;

    .logoContainer{
      font-family:lobster;
    }
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;


.hamburger-react{
  position:absolute;
  z-index:999999;
}
`;

const MobileContent = styled.div`


  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileLinksPageContainer = styled(motion.div)`
  height:100vh;
  width:100vw;
  background-color:black;
  position:fixed;

  #mobilePageLinks{
        display: flex;
    flex-direction: column;
        height: 90%;
    justify-content: center;
    align-items: center;
  }
  #mobilePageLinks a{
    margin-top:1em;
    color:white;
    text-decoration:none;
    font-size:2em;
    font-weight:bold;
  }
  #mobileContactContainer{
    display:flex;
    justify-content:center;
        bottom: 2em;
    position: absolute;
    width: 100%;
  }

  #mobileContactIcons{
    font-size:1.5em;
    display:flex;
    justify-content:space-around;
    width:50%;
  }
  @media (min-width: 768px) {
    display:none;
  }

`

const DesktopContent = styled.div`
  display:flex;
  justify-content:space-around;
  width:50%;


  @media (max-width: 768px) {
    display:none;
  }

#pageLinks{
    display:flex;
    justify-content:space-around;
    width:50%;
}

#contactIcons{
    display:flex;
    justify-content:space-evenly;
    width:25%;
}
a{
    font-size:0.65em;
    text-decoration:none;
    color:white;

    transition:color 0.5s;

    &:hover{
        color:#F90141;
    }
}

`;

const MobileContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: ${props => props.isOpen ? '999' : '-1'};

.hide
{
    display:none;
}

@media (min-width: 768px) {
    display: none;
}

`

const MobileLinksPage = ({isOpen,setOpen}) => {

    const item = {
        hidden: { opacity: 0,y:25 },
        show: { opacity: 1,y:0 }
    }

    const linksVariant = {
  hidden: {opacity:0},
  show:{
    opacity:1,
    transition:{
        delayChildren:1,
        staggerChildren: 0.2,
        type:"spring",
        stiffness: 30
  }
}
}


const mobilePageVariant = {
  hidden: { 
    opacity: 0, 
    height: "0vh", 
    width: "0vw", 
    borderRadius: "100%",
    transition:{
      duration:1

    }
  
  },
  show: {
    opacity: 1,
    height: "100vh",
    width: "100vw",
    borderRadius: "0%",
    transition: {
      duration: 1,
      stiffness: 30,
    },
  },
};



  return (
    
    <MobileContainer
        isOpen={isOpen}
    >
    <MobileLinksPageContainer 
    variants={mobilePageVariant} 
    id="linksPageContainer"
    animate={isOpen ? "show" : "hidden"}
    initial={"hidden"}
    
    >
      <motion.div variants={linksVariant} initial="hidden" animate={isOpen ? "show" : "hidden"} id="mobilePageLinks">
        <motion.a variants={item} href="#app" onClick={() => setOpen(false)}>
          Home
        </motion.a>
        <motion.a variants={item} href="#aboutSection" onClick={() => setOpen(false)}>
          About
        </motion.a>
        <motion.a variants={item} href="#projectSection" onClick={() => setOpen(false)}>
          Projects
        </motion.a>
      </motion.div>
      <motion.div id="mobileContactContainer">
      <motion.ul  variants={linksVariant}
      animate={isOpen ? "show" : "hidden"}
      id="mobileContactIcons">
        <motion.li variants={item}>
        <a href="https://www.linkedin.com/in/hamed-diakite-ba2400152/" target="_blank">
          <FontAwesomeIcon
            color="white"
            size="1x"
            cursor="pointer"
            icon={faLinkedinIn}
          />
        </a>
        </motion.li>
        <motion.li variants={item}>
        <a href="https://github.com/hdkid7" target="_blank">
          <FontAwesomeIcon
            color="white"
            size="1x"
            cursor="pointer"
            icon={faGithub}
          />
        </a>
        </motion.li>
      </motion.ul>
      </motion.div>
    </MobileLinksPageContainer>
    </MobileContainer>
  );
          



}

const Nav = ({autoScroll}) => {
  const [scroll, setScroll] = useState("");


  const links = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.14
    }
  }
}

const item = {
  hidden: { opacity: 0,y:-25 },
  show: { opacity: 1,y:0 }
}

  const [isOpen,setOpen] = useState(false);

  isOpen ? disableBodyScroll(document) : enableBodyScroll(document)


  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY == 0 ? "navTransRev" : "navTrans");
    });
  }, []);

  return (
    <>
    <MobileLinksPage isOpen={isOpen} setOpen={setOpen}/>
    <StyledNav
      className={isOpen ? "navTransRev" : scroll}
      variants={links}
      initial="hidden"
      animate="show"
    >
      <NavContainer id="navContainer">
        <motion.div variants={item} id="logoContainer">
          <motion.a
            style={{
              fontFamily: "lobster",
              textDecoration: "none",
              color: "white",
              fontSize: "1.2em",
            }}
            href="https://hameddiakite.com"
            class="logo"
          >
            HD
          </motion.a>
        </motion.div>
        <MobileContent>
          <Hamburger
          toggled={isOpen}
          onToggle={toggled => {
  if (toggled) {
      setOpen(true);
  } else {
    setOpen(false);
  }}}
          
          
           color="white" size={20} />
        </MobileContent>
        <DesktopContent>
          <motion.div id="pageLinks">
            <motion.a variants={item} href="#app">
              Home
            </motion.a>
            <motion.a variants={item} href="#aboutSection">
              About
            </motion.a>
            <motion.a variants={item} href="#projectSection">
              Projects
            </motion.a>
          </motion.div>
          <motion.ul id="contactIcons">
            <motion.li variants={item}>
             <a style={{fontSize:'1em'}} href="https://www.linkedin.com/in/hamed-diakite-ba2400152/" target="_blank">
              <FontAwesomeIcon
                color="white"
                size="1x"
                cursor="pointer"
                icon={faLinkedinIn}
              />
            </a>
            </motion.li>
            <motion.li variants={item}>
            <a style={{fontSize:'1em'}} href="https://github.com/hdkid7" target="_blank">
              <FontAwesomeIcon
                color="white"
                size="1x"
                cursor="pointer"
                icon={faGithub}
              />
            </a>
            </motion.li>
          </motion.ul>
        </DesktopContent>
      </NavContainer>
    </StyledNav>
    </>
  );
};

export default Nav;
