import React from "react";
import ReactDOM from "react-dom/client";
import {faBars} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {keyframes} from 'styled-components';
import styled from 'styled-components';
import portrait from "../img/portrait.jpg"
import logo from "../img/pancakeswap.png";
import {motion} from "framer-motion"

const FooterContainer = styled.footer`
    width:100%;
    background:black;
    color:white;

    img{
        margin-top:0.5em;
        width:2.5em;
    }
    p{
        margin:0.4em;
    }
    .footerContent{
        height:100%;
    display:flex;
    align-items:center;
    flex-direction:column;
    }
        .logoContainer{
            margin-top:0.5em;
            background-color:white; 
            border-radius:20em;
            width:2.5em;
            height:2.5em;
            display: flex;
            justify-content: center;
            align-items: center;
      font-family:lobster;
        } 
        .logoContainer a{
            color:black;
            -webkit-box-shadow: -1px 2px 38px -23px rgba(0,0,0,0.75);
            -moz-box-shadow: -1px 2px 38px -23px rgba(0,0,0,0.75);
            box-shadow: -1px 2px 38px -23px rgba(0,0,0,0.75);
        }
`

const Footer = () => {
  return (
    <FooterContainer id="footerSection">
      <div className="footerContent">
        <motion.div  class="logoContainer">
          <motion.a
            style={{
              fontFamily: "lobster",
              textDecoration: "none",
              fontSize: "1.2em",
            }}
            href="https://hameddiakite.com"
            class="logo"
          >
           <p>HD</p>
          </motion.a>
        </motion.div>
        <p style={{ fontWeight: "bold" }}>Perfecting skills on a daily basis</p>
        <p style={{ fontSize: "0.8em", marginBottom: "1em" }}>
          Made with ♥ and care by myself © 2022
        </p>
      </div>
    </FooterContainer>
  );
};

export default Footer;