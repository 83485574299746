import React from "react";
import ReactDOM from "react-dom/client";
import {faBars, faChevronDown} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {keyframes} from 'styled-components';
import styled from 'styled-components';
import Nav from "./Nav";
import {motion} from "framer-motion"

const StyledContainer = styled(motion.div)`

`;

const GreetingSection = styled.div`
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    width:100%;
    height:100vh;
    text-align-center;
    position:relative;

    h1{
      font-size:1.5em;
    }

    h3{
      font-size:0.9em;
      color:white;
      line-height:1.7em;

      span{
        color:#F90141;
      }
    }

    img{
        height:10em;
    }
    .portraitContainer{
      margin-top:3em;
      display:flex;
      justify-content:center;
      width:100%;
    }

    #welcomeTextContainer{
      font-weight:bold;
      display:flex;
      justify-content:center;
      width:100%;
    }

    #welcomeSection{
      width:100%;
      display:flex;
      justify-content:center;
      align-items:center;
    }

    .welcomeContainer{
    display: flex;
    flex-direction:column;
    width: 100%;
        justify-content: center
    align-items:center;
    }

  @media (min-width: 768px) {
    .welcomeContainer{
      width:65%;
      flex-direction:row;

    }

    h3{
      font-size:0.7em;
    }

    .portraitContainer{
      margin: 4em;
      margin-top:0;
      display:flex;
      justify-content:center;
      width:100%;
    }
   
  }
`;

const TitleText = styled(motion.h1)`
  line-height: 1em;
  color: ${props => props.primary ? "#F90141" : "white"};
  margin-top: ${props => props.nospace ? "0em" : "0.5em"};
  margin-bottom: ${props => props.nospace ? "0em" : "0.5em"};
  `;

const Welcome = () => {

  const arrowDiv =
    {
      hidden:{
        opacity:0
      },
      show:{
        opacity:1,
    transition: {
      delay:2.4,
      duration: 0.9,
    }
      }
    }
  
  const arrowVariant = 
  {
  updown: {
    y: [15,-15],
    transition: {
      duration: 0.9,
      // how many times we want to repeat the animation
      yoyo: Infinity
    }
  }
};

  const links = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren:1.2,
        staggerChildren: 0.2,
        type:"spring",
        stiffness: 30
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 25 },
    show: { opacity: 1, y: 0,  },
  };



    return (
      <StyledContainer variants={links} initial="hidden" animate="show">
        <GreetingSection>
          <section id="welcomeSection">
            <div class="welcomeContainer">
              <motion.div id="welcomeTextContainer">
                <div>
                  <TitleText variants={item} primary nospace>
                    Hi, my
                  </TitleText>
                  <TitleText variants={item} primary nospace>
                    name is Hamed.
                  </TitleText>
                  <TitleText variants={item}>I build simplicity</TitleText>
                  <motion.h3 variants={item}>
                    I'm a <span>Software Engineer</span> based in New York, who
                    loves tinkering with both software and hardware.
                  </motion.h3>
                </div>
              </motion.div>
            </div>
          </section>
          <motion.div
            initial="hidden"
            animate="show"
            variants={arrowDiv}
            style={{ bottom: "1em", position: "absolute" }}
          >
            <motion.div
              animate="updown"
              variants={arrowVariant}
            >
              <FontAwesomeIcon
                color="white"
                size="1x"
                cursor="pointer"
                icon={faChevronDown}
              />
            </motion.div>
          </motion.div>
        </GreetingSection>
      </StyledContainer>
    );
}

export default Welcome;
