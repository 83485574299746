import React from "react";
import ReactDOM from "react-dom/client";
import {faBars} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {keyframes} from 'styled-components';
import styled from 'styled-components';
import portrait from "../img/portrait.png"
import {motion} from "framer-motion"

const StyledContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 85vh;
  margin-bottom:12em;

  img {
    height: 15em;
  }

  .portraitContainer{
  }

  @media (min-width: 768px) {
  width:100%;
      justify-content:space-around;
      flex-direction: row;
  }
`;

const Title = styled.p`
    font-weight:bold;
    font-size:2.5em;
    margin-bottom:0.7em;
    color: #F90141;
}
`

const SubTitle = styled.p`
    font-size:1.1em;
    margin-bottom:0.5em;
    font-weight:bold;
    color:white;
`

const SubText = styled.p`
      font-size:0.9em;
    line-height: 1.6em;
    color:white;
  @media (min-width: 768px) {
    font-size:0.7em;
      padding:0;
      text-align:left;
  }
`

const AboutContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction:column;
  @media (min-width: 768px) {
    flex-direction:row;
      text-align:left;
      align-items:center;
      width:90%;
  }
`

const AboutText = styled.div`
      width: 100%;
      margin:3em;
      height:300px;

  @media (min-width: 768px) {
      width:40%;
  }
`

const image = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            delay:2.1,
            type:"spring",
            stiffness: 30
        },
    },
};

const About = () => {
    return (
      <StyledContainer
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, amount: 0.6 }}
        id="aboutSection"
      >
        <AboutContainer id="aboutInfoContainer">
          <AboutText>
            <Title>About Me</Title>
            <SubTitle>Thanks for viewing my portfolio!</SubTitle>
            <SubText>
				I'm excited to expand my skills and collaborate on user-friendly, visually appealing products. I'm dedicated to continuous improvement, striving for the highest quality in my code. Explore my recent projects below.
            </SubText>
          </AboutText>
                <motion.div
                    initial="hidden"
                    class="portraitContainer">
                    
                    <img draggable="false" src={portrait} />
                </motion.div>
        </AboutContainer>
      </StyledContainer>
    );
}

export default About;
