import React from "react";
import ReactDOM from "react-dom/client";
import {faBars, faArrowUpRightFromSquare, faUpRightFromSquare} from '@fortawesome/free-solid-svg-icons'
import {
  faGithub
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {keyframes} from 'styled-components';
import styled from 'styled-components';
import portrait from "../img/portrait.jpg"

const StyledContainer = styled.div`
max-width:400px;
width:90%;
background:white;
border-radius: 7px;
height:8em;
padding:1em;
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  cursor:pointer;
  transition:transform 0.5s;
  postion:relative;





  `

  const Title = styled.p`
    text-align:center;
    font-weight:bold;
    color:

    p{

    }
  
  `
  const TechCsv = styled.div`

        text-align: center;
    position: relative;
    bottom: -0.5em;
    font-size:0.8em;

  `
    const Description = styled.div`
      margin-top:0.5em;
      font-size:0.65em;
      height:6em; 
    
    `

    const Links = styled.div`
          text-align: center;
    bottom: 0em;
    position: relative;

    svg{
      margin-right:0.5em;
    }
    
    
    `

const AltProject = ({ img, title, desc, techCsv, projectLink, isSwap }) => {
  return (
    <StyledContainer>
      <Title>
        {title}
      </Title>
      <Description>
        {desc}
      </Description>
      <Links>
            <a target="_blank" href={projectLink}>
              <FontAwesomeIcon color="black" size="1x" cursor="pointer" icon={faUpRightFromSquare} />
            </a>
      </Links>
      <TechCsv>
        {techCsv}
      </TechCsv>
      
    </StyledContainer>
  );
};

export default AltProject;
