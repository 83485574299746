
import React from "react";
import ReactDOM from "react-dom/client";
import {
  faGithub
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {keyframes} from 'styled-components';
import styled from 'styled-components';
import portrait from "../img/portrait.jpg"
import {faArrowUpRightFromSquare} from '@fortawesome/free-solid-svg-icons'
import {motion} from "framer-motion"

const TitleLink = styled.a`
color: inherit;
  position: relative;
text-align:center;
font-weight:bold;
text-decoration:none;
&:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #150841;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
  }

&:hover:before {
  transform-origin: left;
  transform: scaleX(1);
}
`

const StyledContainer = styled(motion.div)`
display:flex;
justify-content:center;
flex-direction: ${(props) => (props.swapped ? "column-reverse" : "column")};

  .imgContainer{
    margin-top:2em;
    max-width:700px;
    height:22em;
  }

  .textContainer{
    display: flex;
flex-direction: column;
align-items: center;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
      border-radius:22px;
      height:initial;
    margin-top:2em;
    height:10em;
    width:100%;
    background: #F90141;
    position:relative;
  }
  .techCont{
bottom: 0.8em;
position: absolute;
  }
  .techCont p{
    display:none;
  }
  .description{
    margin: 0.7em;
font-size: 0.9em;
  }
  img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius:22px;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }

    .techCont ul{
      display: flex;
    font-size: 0.7em;
    list-style: none;
    color: white;
    font-weight: bold;
    flex-wrap: wrap;

    }

    .techCont li{
          margin-right: 0.8em;
    }



    }
    @media (min-width: 1300px){
      margin:2em;
    flex-direction:row;
    align-items:center;
    margin-top:4em;
    margin-bottom:4em;
    height:15em;
    ${(props) => (props.swapped ? "margin-right:4em;" : "margin-left:4em;")};

  .techCont p{
    display:inline;
  }
    .techCont{
    left: ${(props) => (props.swapped ? "0em" : "4em")};
      bottom: -4.5em;
    position: absolute;
    }
    .imgContainer{
      height: 16em;
      width: 26em;
      max-height: 500px;
      margin-top: 0em;
    }

    .techCont ul{
    width: initial;
    }
    .textContainer p{
      margin:0.8em;
      font-size:0.6em;
    }
    .textContainer{
      line-height: 1.4rem;
     height:6.6em;
     width:35%;
      margin-top:0em;
      z-index: 999;
    position: relative;
    right: ${(props) => (props.swapped ? "-2em" : "2em")};
    }

  } 
  @media (min-width: 800px) and (max-width: 1349px){
      margin:2em;
    flex-direction:row;
    align-items:center;
    margin-top:4em;
    margin-bottom:4em;
    height:15em;
    ${(props) => (props.swapped ? "margin-right:4em;" : "margin-left:4em;")};

  .techCont p{
    display:inline;
  }
    .techCont{
    left: ${(props) => (props.swapped ? "0em" : "4em")};
      bottom: -4em;
    position: absolute;
    }
    .imgContainer{
      height: 16em;
      width: 26em;
      max-height: 500px;
      margin-top: 0em;
    }

    .techCont ul{
    width: initial;
    }
    .textContainer{
     height:7.6em;
     width:60%;
      margin-top:0em;
      z-index: 999;
    position: relative;
    right: ${(props) => (props.swapped ? "-2em" : "2em")};
    }

    .textContainer p{
      margin:0.8em;
      font-size:0.7em;
      line-height: 1.4em;
    }
    .content{

    }
 }
`

const LinkDiv = styled.div`
position: absolute;
bottom: 2em;
  @media (min-width: 700px) {
position: absolute;
    bottom: 0.5em;
    left: 0.5em;
  }

`
const Project = ({ img, title, desc, techCsv, projectLink, isSwap }) => {
  const techCsvHtml = techCsv.split(",").map(x => <li>{x}</li>)

  const itemSwapped = {
    "initial":{
        opacity:0,
        x:120
    },
    "slide":{
      opacity:1,
      x:0,
      transition: {
      type: "spring",
      bounce: 0.6,
      duration: 1.6
    }
    }
  }
  const item = {
    "initial":{
        opacity:0,
        x:-120
    },
    "slide":{
      opacity:1,
      x:0,
      transition: {
      type: "spring",
      bounce: 0.6,
      duration: 1.6
    }
    }
  }
  return isSwap ? (
    <StyledContainer 
      initial="initial"
      whileInView="slide"
      variants={itemSwapped}
       viewport={{ once: true, amount: 0.7 }} 
    >
      <div className="imgContainer">
        <img src={img} />
      </div>
      <div className="textContainer">
        <div style={{ textAlign: "center", marginTop: "0.5em" }}>
          <TitleLink target="_blank" href={projectLink}>{title}</TitleLink>
        </div>
        <p className="description">{desc}</p>
        <LinkDiv>
        <a target="_blank" href={projectLink}>
          <FontAwesomeIcon
            style={{ marginRight: "0.5em", cursor: "pointer" }}
            color="white"
            size="1x"
            icon={faArrowUpRightFromSquare}
          />
        </a> 

        </LinkDiv>
        <div className="techCont">
          <p
            style={{
              marginBottom: "0.5em",
              marginLeft: 0,
              fontWeight: "bold",
              fontSize: "0.7em",
              color: "#F90141",
            }}
          >
            Made with
          </p>
          <ul>{techCsvHtml}</ul>
        </div>
      </div>
    </StyledContainer>
  ) : (
    <StyledContainer
      swapped
      initial="initial"
      whileInView="slide"
      variants={item}
       viewport={{ once: true, amount: 0.7 }} 
    >
      <div className="textContainer">
        <div style={{ textAlign: "center", marginTop: "0.5em" }}>
          <TitleLink target="_blank" href={projectLink}>{title}</TitleLink>
        </div>
        <p className="description">{desc}</p>
        <LinkDiv>
        <a target="_blank" href={projectLink}>
          <FontAwesomeIcon
            style={{ marginRight: "0.5em", cursor: "pointer" }}
            color="white"
            size="1x"
            icon={faArrowUpRightFromSquare}
          />
        </a> 
        </LinkDiv>
        <div className="techCont">
          <p
            style={{
              marginBottom: "0.5em",
              marginLeft: 0,
              fontWeight: "bold",
              fontSize: "0.8em",
              color: "#F90141",
            }}
          >
            Made with
          </p>
          <ul>{techCsvHtml}</ul>
        </div>
      </div>
      <div className="imgContainer">
        <img src={img} />
      </div>
    </StyledContainer>
  );
};

export default Project;
