import ReactDOM from "react-dom/client";
import React from "react";
import Nav from "./components/Nav";
import Welcome from "./components/Welcome";
import About from "./components/About";
import AltProjectSection from "./components/AltProjectSection";
import ProjectSection from "./components/ProjectSection";
import Footer from "./components/Footer";
import styled from 'styled-components';

const Height = styled.div`

padding:0px 25px;
overflow:hidden;

  @media (min-width: 500px) {
padding:0px 50px;
}

  @media (min-width: 1000px) {
padding:0px 75px;
}
  @media (min-width: 1200px) {
padding:0px 100px;
}
  @media (min-width: 1500px) {
padding:0px 150px;
}
`



const App = () => {
  return (

    <div>
        <Nav/>
        <Height>

        <Welcome />
        <About />
        <ProjectSection />
        <AltProjectSection />

        </Height>
      <Footer />
    </div>
  );
}

export default App;